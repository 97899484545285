@import '../_cart-fast';
@import '_header-account';
@import '_header-actions';
@import '_header-drawer';
@import '_header-brand';
@import '_header-hero';
@import '_header-logo';
@import '_header-top';
@import '_header-links';
@import '_header-variables';

.header {
  z-index: @header-z-index;
  background-color: @header-bg-color-1;
  user-select: none;
  min-height: @header-topbar-height-xs;

  a {
    outline: none;
  }

  .header-overlay {
    background-color: black;
    position: absolute;
    opacity: @overlay-opacity;
    width: 100vw;
    height: 100vh;

    &.open {
      opacity: @overlay-opacity;
      animation: showOverlay .5s ease-in-out;
    }

    &.closed {
      animation: hideOverlay .25s ease-in-out;
    }
  }
}

.header-text-color {
  color: @header-text-color;
  text-decoration-line: none !important;
}

.bg-header-1 {
  background-color: @header-bg-color-1;
}

.bg-header-2 {
  background-color: #191817;
}

@media (@min-screen-sm) {
  .header {
    min-height: @header-topbar-height-sm;
  }

  .button-my-account {

  }
}

@media (@max-screen-sm) {
  .icon-cr.icon-bag:before {
    color: @color-primary-4;
  }
}

