@import '../../../../../submodules/src/styles/mixins';
@import '_header-variables';
@import '../../_variables';

#header-drawer-icon {
  font-size: 1.333rem; // a mesma que o h4 para alinhar com o texto da barra
  flex: 0 0 1.5em;
  background-color: @header-text-color;
  mask-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'><path d='M47.19,23.25a2.29,2.29,0,0,0,2.3-2.42A2.45,2.45,0,0,0,47,18.66H16.81a2.3,2.3,0,1,0,0,4.59Z'/><path d='M47.19,34.3a2.31,2.31,0,0,0,2.3-2.43A2.45,2.45,0,0,0,47,29.7H16.81a2.3,2.3,0,0,0,0,4.6Z'/><path d='M47.19,45.34a2.31,2.31,0,0,0,2.3-2.42A2.45,2.45,0,0,0,47,40.75H16.81a2.3,2.3,0,1,0,0,4.59Z'/></svg>");
  mask-position: 0 center;
  mask-repeat: no-repeat;
  margin: auto .5rem;
  height: 3rem;
}

.header-account-icon, .header-cart-icon {
  width: @header-icon-size;
  height: @header-icon-size;
  display: block;
}

.header-cart-icon-wrapper {
  margin: 0 .5rem;
}

#header-account, .header-cart {
  padding: 0;
  position: relative;
}

#header-account {
  display: none;

  span, a {
    margin: auto 0;
  }

  .header-account-icon {
    color: @header-text-color;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover .header-account-icon {
      color: @color-accent-1;
    }
  }
}

.header-cart {
  .header-cart-counter {
    background-color: @color-accent-1;
    height: 1rem;
    font-size: 10px;
    color: white;
    text-align: center;
    padding: 0 .25rem;
    border-radius: @radius-default;
    line-height: 1.2rem;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover .header-cart-icon {
      color: @color-accent-1;
    }
  }
}

@media (@min-screen-sm) {
  .header-account-icon, .header-cart-icon {
    margin: auto 0;
  }

  #header-account, .header-cart {

    .header-topbar-btn {
      padding: .5em;
      display: flex;
      cursor: pointer;
      border-radius: @radius-default;
      align-items: center;
      color: @header-text-color;

      @media (hover: hover) and (pointer: fine) {
        a:hover {
          text-decoration: none;
        }
      }
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        a {
          text-decoration: none;
        }

        .header-account-label, .header-cart-label {
          color: @color-accent-1;
        }

        .header-topbar-btn {
          background-color: @color-default-1;
        }
      }
    }
  }

  #header-account {
    display: block;

    .header-account-label {
      .text-ellipsis();
      max-width: 13rem;
    }

    .cr-popover-content {
      width: 12rem;

      .h5 {
        padding: .5rem 0;
      }
    }
  }

  #header-cart-desktop .cr-popover-content {
    width: 29rem;

    &:before {
      right: 2rem;
    }

    h4 {
      padding: 0;
      margin: 1rem 0;
    }

    ul {
      list-style: none;

      li.product-list-item {
        margin-bottom: 1rem;

        :nth-child(2) {
          overflow: hidden;

          p {
            .text-ellipsis();
          }
        }

        .product-list-item-thumb {
          flex-basis: 65px;
        }

        .product-list-item-price {
          margin-left: auto;
          text-align: right;
        }
      }
    }

    .cart-footer {
      text-transform: uppercase;
      font-size: 16px;
    }
  }
}
