@import '../_variables';

.btn {
  background-color: @color-default-5;
  border-color: @color-default-5;
  color: white;

  &:not([disabled]):not(.btn-disabled) {
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: @color-accent-1;
        border-color: @color-accent-2;
        color: white;
      }
    }

    &:active {
      background-color: @color-accent-2;
      box-shadow: none;
    }
  }

  &.btn-header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &.btn-height {
    height: 3rem;
  }

  &.btn-crie-sua-loja {
    background-color: #DD2C59;
    color: #F7F7F5;
  }
}