@import "../_variables";

.slider {
  position: relative;

  &.slider-arrows-padding {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  &.slider-lazy {
    .slide {
      position: relative;

      > .slide-lazy-wrapper {
        height: 0;
        width: 100%;

        img {
          width: 100%;
        }
      }
    }
  }

  &:not(.slider-ready) {
    display: flex;
    overflow: hidden;

    > :not(.slider-placeholder) {
      //visibility: hidden;
      flex: 0 0 auto;
      width: 100%;
    }
  }

  .slides-container {
    overflow: hidden;
    position: relative;

    .slides {
      display: flex;

      .slide > * {
        margin: 0;
      }

      &.slides-flex-width .slide {
        flex: 1 1 0;
      }

      &.disabled-links .slide {
        pointer-events: none;
      }

      &.is-animating {
        transition: transform 400ms cubic-bezier(0.5, 0, 0.5, 1);
      }
    }

    &.slides-container-vertical .slides {
      display: block;
    }

    &.slides-container-vertical .slide {
      padding-top: 0;
    }
  }

  .slider-arrow() {
    position: absolute;
    top: 50%;
    width: 2.5rem;
    height: 100%;
    cursor: pointer;
    transform: translateY(-50%);
    z-index: 1;
    opacity: .5;
    transition: visibility @transition-duration @transition-curve;

    &.slick-disabled {
      opacity: .3;
      cursor: auto;
    }

    .icon-cr {
      position: absolute;
      top: 50%;
      margin-top: -50%;
      width: 2.5rem;
      height: 2.5rem;
      background-color: white !important;
      border-radius: 1.25rem;
    }
  }

  .slider-arrow-left {
    .slider-arrow();
    left: 0;

    .icon-cr {
      right: 0;
    }
  }

  .slider-arrow-right {
    .slider-arrow();
    right: 0;

    .icon-cr {
      left: 0;
    }
  }

  .slides-pagination {
    text-align: center;
    width: 100%;

    div.slider-bullet {
      display: inline-block;
      padding: 5px;
      opacity: .2;
      cursor: pointer;
      transition: opacity @transition-duration @transition-curve;

      > span {
        width: 12px;
        height: 12px;
        display: inline-block;
        border-radius: 100%;
        background-color: @color-default-4;
      }

      &:hover {
        opacity: .5;
      }

      &.active {
        opacity: .8;
      }
    }
  }
}

.slider-placeholder {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: " ";
  position: absolute;
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: hsla(30, 17%, 79%, .8);
  will-change: opacity;
}