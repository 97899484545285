@import '../../_variables';
@import '_header-variables';

.header {
  #header-products-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 2;
    top: 0;

    .header-overlay {
      opacity: 0;
    }

    &.open {
      .header-overlay {
        opacity: @overlay-opacity;
        animation: showOverlay .5s ease-in-out;
      }

      #header-products {
        transform: translateX(0);
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2), 0 0 10px 0 rgba(0, 0, 0, 0.19);
        animation: openDrawer .25s ease-in-out;
        padding-bottom: 50px; // previne o Safari de mostrar os botões inferiores e atrapalhar a rolagem
      }
    }

    @media (@max-screen-sm) {
      &.closed {
        .header-overlay {
          animation: hideOverlay .25s ease-in-out;
        }

        #header-products {
          animation: closeDrawer .25s ease-in-out;
        }
      }
    }
  }

  #header-products {
    background-color: @header-bg-color-1;
    z-index: 1;
    width: 300px;
    transform: translateX(-100%);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .header-drawer-items {
    a {
      color: @header-bg-color-2;
    }

    .h4 {
      padding: .75rem 1.5rem;
    }

    .h5 {
      padding: 1rem 1.5rem;
    }

    .header-collapsible-title:after {
      content: '';
      position: absolute;
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
      transition: transform @transition-duration @transition-curve;
      background-color: @header-text-color;
      mask-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'><path d='M30.34 40a2.39 2.39 0 0 0 3.31 0l12.73-12.7a2.37 2.37 0 0 0 .69-1.66 2.34 2.34 0 0 0-.69-1.64 2.4 2.4 0 0 0-3.32 0L32 35 20.94 24a2.34 2.34 0 0 0-3.32 0 2.36 2.36 0 0 0 0 3.32z'/></svg>");
    }

    .header-collapsible-title.opened:after {
      transform: translateY(-50%) rotate(180deg);
    }

    .collapsible {
      background-color: @header-bg-color-2;
    }
  }
}
