@import '_mixins';

ul.cr-breadcrumb {
  display: flex;
  list-style-type: none;
  padding-left: 0;

  li {
    .text-ellipsis();
    position: relative;
    margin-right: .25rem;
    height: 2rem;
    font-size: 1.1rem;
    line-height: 2rem;

    &:first-child {
      padding-left: 0;

      &:before {
        content: none;
      }
    }

    &:not(:first-child) {
      padding-left: 1.25rem;

      &::before {
        position: absolute;
        left: 0;
        top: .5rem;
        content: '';
        width: 1rem;
        height: 1rem;
        background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'><path d='M46.38 36.69L33.66 24a2.33 2.33 0 0 0-3.31 0L17.62 36.7a2.4 2.4 0 0 0-.69 1.66 2.34 2.34 0 0 0 .69 1.64 2.4 2.4 0 0 0 3.32 0L32 29l11.06 11a2.35 2.35 0 0 0 3.32-3.32z'/></svg>") no-repeat 50% 50%;
        transform: rotate(90deg);
      }
    }
  }
}