@import '../_variables';

.icon-cr {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  flex-basis: 1.5rem;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: currentColor;
  }

  &.circle {
    &:before {
      background-color: white;
    }
  }

  &.circle {
    width: 1em !important;
    height: 1em !important;
    border-radius: 50%;
    background-color: currentColor;

    &:before {
      mask-size: 70%;
    }
  }

  &.icon-cr-hover {
    transition: background-color @transition-duration @transition-curve;
    border-radius: @radius-default;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    flex-shrink: 0;
    flex-basis: 2rem;
    mask-origin: content-box;

    &:before {
      mask-size: 70%;
    }

    @media (hover: none) {
      background-color: @color-default-1;
    }

    @media (hover: hover) {
      &:hover {
        background-color: @color-default-1;
      }
    }

    &:active {
      background-color: @color-default-2;
    }
  }

  .frame &.icon-cr-hover {
    @media (hover: hover) {
      &:hover {
        background-color: @color-default-2;
      }
    }

    &:active {
      background-color: @color-default-3;
    }
  }
}

h1, h2, h3, h4, h5, h6, p, span {
  > i.icon-cr:not(.icon-cr-hover) {
    position: relative;
    top: 0.15em;
    height: 1em;
    width: 1.3em;
  }
}

.btn > i.icon-cr {
  top: 0;
}


.icon-hover {
  padding: .25rem;
  border-radius: @radius-default;
  cursor: pointer;
  transition: background @transition-default;

  &:hover {
    background: @color-default-1;
  }

  &:active {
    background: @color-default-2;
  }
}
