.collapsible {
  overflow: hidden;
  max-height: 0;
  transition: max-height .25s cubic-bezier(0, 1, 0, 1);

  &.opened {
    max-height: 10000px;
    transition: max-height 1s ease-in-out;
    animation: openCollapse .1s linear forwards .25s; // utiliza animação pra aplicar overflow somente após a transição
  }
}

.collapsible-x {
  overflow: hidden;
  max-width: 0;
  transition: max-width .25s cubic-bezier(0, 1, 0, 1);

  &.opened {
    max-width: 10000px;
    transition: max-width 1s ease-in-out;
    animation: openCollapse .1s linear forwards .25s; // utiliza animação pra aplicar overflow somente após a transição
  }
}

@keyframes openCollapse {
  0% {
    overflow: hidden;
  }
  100% {
    overflow: visible;
  }
}