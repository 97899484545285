@import "mixins";

.cr-spinner {
  display: block;
  margin: 0 auto;
  border-radius: 100%;
  animation: cr-spinner-spin .8s linear infinite;
}

.cr-spinner {
  .spinner-size(2.5rem, @color-default-2, 4px);
}

.cr-spinner-small {
  .spinner-size(1.5rem, @color-default-2);
}

.cr-spinner-inline {
  .spinner-size(1em, @color-default-2);
}

@keyframes cr-spinner-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
