@import '../../_variables';
@import '_header-variables';

.header-logo-penca {
  margin: .5rem;

  #header-mobile & {
    margin-left: 15px;
    color: @color-white;
    height: 2.6rem;
    width: 2rem;
  }

  #header-desktop & {
    color: @color-white;
    height: 2.5rem;
    width: 2.5rem;
  }
}

.img-logo-store {
  max-width: 100%;
  transition: max-width 400ms;
  color: @color-white;

  &:hover {
    color: @color-white;
  }
}

.img-logo-scroll-store {
  max-width: 50%;
  transition: max-width 400ms;
  color: @color-white;

  &:hover {
    color: @color-white;
  }
}

.header-logo-up {
  height: 4rem;
  width: 14rem;
  transition: width 400ms;
  color: @color-white;

  &:hover {
    color: @color-white;
  }
}

.header-logo-scroll-up {
  height: 4rem;
  width: 9rem;
  transition: width 400ms;
  color: @color-white;

  &:hover {
    color: @color-white;
  }
}

.logo-dropdown-up {
  height: 2rem;
  width: 9rem;
  color: @color-white;

  &:hover {
    color: @color-white;
  }
}

#header-mobile-logo {
  height: 3rem;
}

#header-desktop-logo-wrapper {
  flex-grow: 0;
  flex-basis: 13rem;
  position: relative;

  .header-logo {
    background-position-x: left;
  }

  .icon-chevron-down {
    position: absolute;
    display: block;
    right: 0;
    top: 50%;
    width: 1.5rem;
    height: 1.5rem;
    opacity: 0;
    cursor: pointer;
    transform: translateY(-50%);
    transition: opacity .25s @transition-curve;
    color: @color-white;
  }
}
