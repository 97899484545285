@import 'variables';
@import 'mixins';

// <editor-fold desc="Button">

@media (hover: hover) and (pointer: fine) {
  a.btn:hover {
    text-decoration: none;
  }
}

.btn {
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 0 .75em;
  border-radius: @radius-default;
  border: @input-border-width solid @color-default-3;
  background-color: @color-default-2;
  color: @color-default-text-5;
  font-family: @font-primary;
  font-size: 1rem;
  font-weight: @regular-weight;
  cursor: pointer;
  transition: background-color @transition-curve .2s, border-bottom-color @transition-curve .2s, color @transition-curve .2s;
  outline: none !important;
  white-space: nowrap;
  user-select: none;
  line-height: @input-height-xs;

  &::-moz-focus-inner {
    border: 0;
  }

  a& {
    text-decoration: none;

    &:hover {
      color: @color-default-text-5;
    }
  }

  &.btn-sm {
    font-size: .9em;
    line-height: @input-height-small-xs;
  }

  &.btn-lg {
    font-size: 1.1em;
    line-height: @input-height-large-xs;
  }

  &.btn-xl {
    font-size: 1.2em;
    line-height: @input-height-extra-large-xs;
  }

  @media (@min-screen-sm) {
    line-height: @input-height-sm;

    &.btn-sm {
      line-height: @input-height-small-sm;
    }

    &.btn-lg {
      line-height: @input-height-large-sm;
    }

    &.btn-xl {
      line-height: @input-height-extra-large-sm;
    }
  }

  &.btn-image {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:after {
      content: '\200B';
    }

    > img, i.icon-cr {
      width: @input-height-xs - 1rem;
      height: @input-height-xs - 1rem;

      + * {
        margin-left: .5rem;
      }
    }

    &.btn-sm {
      > img, i.icon-cr {
        width: @input-height-small-xs;
        height: @input-height-small-xs;
      }
    }

    &.btn-lg {
      > img, i.icon-cr {
        width: @input-height-large-xs;
        height: @input-height-large-xs;
      }
    }

    &.btn-xl {
      > img, i.icon-cr {
        width: @input-height-extra-large-xs;
        height: @input-height-extra-large-xs;
      }
    }

    @media (@min-screen-sm) {
      > img, i.icon-cr {
        width: @input-height-sm;
        height: @input-height-sm;
      }

      &.btn-sm {
        > img, i.icon-cr {
          width: @input-height-small-sm;
          height: @input-height-small-sm;
        }
      }

      &.btn-lg {
        > img, i.icon-cr {
          width: @input-height-large-sm;
          height: @input-height-large-sm;
        }
      }

      &.btn-xl {
        > img, i.icon-cr {
          width: @input-height-extra-large-sm;
          height: @input-height-extra-large-sm;
        }
      }
    }
  }

  &.btn-wide {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  &:not([disabled]):not(.btn-disabled) {
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: @color-default-3;
      }
    }

    &:active, &.btn-active {
      background-color: @color-default-4;
      color: white;
    }
  }

  &.btn-loading {
    position: relative;

    > .btn-spinner-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      visibility: hidden;
    }

    &.loading {
      color: transparent !important;

      > :not(.btn-spinner-wrapper) {
        visibility: hidden;
      }

      > .btn-spinner-wrapper {
        visibility: visible;
      }
    }
  }

  &.btn-primary {
    .btn-variant-states('primary');
  }

  &.btn-danger {
    .btn-variant-states('danger');
  }

  &.btn-success {
    .btn-variant-states('success');
  }

  &.btn-warning {
    .btn-variant-states('warning');
  }

  &.btn-info {
    .btn-variant-states('info');
  }

  &.btn-light {
    background-color: white;
    border-color: @color-default-5;
    color: @color-default-text-5;

    &:not([disabled]):not(.btn-disabled) {
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: @color-default-2;
          border-color: @color-default-5;
          color: @color-default-text-5;
        }
      }

      &:active, &.btn-active {
        background-color: @color-default-3;
        border-color: @color-default-5;
        color: @color-default-text-5;
      }
    }
  }

  &[disabled], &.btn-disabled {
    border-color: @color-default-2;
    background-color: @color-default-1;
    color: @color-default-text-3;
    cursor: auto;
  }

  input[type=file] {
    opacity: 0;
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    cursor: pointer;
  }
}

// </editor-fold>

// <editor-fold desc="Button Group">

.btn-group {
  display: inline-flex;

  > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  > .btn:not(:last-child) {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.btn-group-wide > .btn {
    flex: 1 1 auto;
  }
}

.btn-group-vertical {
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 1rem;

  &:after {
    content: '';
    display: block;
    clear: both;
  }

  > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  > .btn:not(:last-child) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

// </editor-fold>
// <editor-fold desc="Button Group">

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  margin-left: -.25rem;

  > .btn, .btn-group {
    margin-left: .25rem;
    margin-bottom: .25rem;
  }
}

// </editor-fold>