@import '../_variables';
@import '_mixins';

#cart-fast-content-overlay {
  .base-overlay();
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: @header-z-index + 1;
}

#cart-fast-content-mobile {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 95vw;
  max-width: 350px;
  right: -350px;
  background-color: white;
  box-shadow: @default-shadow;
  z-index: @header-z-index + 2;
  display: flex;
  flex-direction: column;
  transition: right @drawer-transition-duration @transition-curve;
  padding: 1rem 1rem @mobile-bottom-spacing;
  overflow-y: auto;

  &.open {
    right: 0;
  }
}

@media (hover: hover) and (pointer: fine) {
  #cart-fast-title {
    display: none;
  }
}

@media (hover: none) and (pointer: coarse) {
  #header-cart-popover {
    display: none;
  }
}