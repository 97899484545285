@import 'variables';

*, :after, :before {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html {
  text-size-adjust: 100%;
}

body {
  margin: 0;
  font-weight: @regular-weight;
}

a {
  text-decoration: none;
  color: @color-primary-3;

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
      color: @color-primary-2;
    }
  }

  &:active {
    color: @color-primary-5;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-grab {
  cursor: grab;
}

.no-pointer-events {
  pointer-events: none;
}

.busy {
  transition: opacity @transition-duration @transition-curve;
  opacity: .5;
  pointer-events: none;
  user-select: none;
}

// <editor-fold desc="Cores">

.bg-white {
  background-color: @color-white !important;
}

.bg-black {
  background-color: @color-black !important;
}

.new-bg-black {
  background-color: #191817;
}

.bg-profile {
  color: @color-primary-600;
  background-color: @color-primary-50;
  box-shadow: 0px 1px 3px 0px #00000040;
  border-radius: 5px;
  padding: 5px 7px;

  .fas {
    margin-right: 0 !important;
  }
}

.text-white {
  color: @color-white !important;
}

.text-black {
  color: @color-black !important;
}

.border-white {
  border-color: white !important;
}

.color-variant(@variant) {
  each(@color-levels, {
    @color: 'color-@{variant}-@{value}';

    .bg-@{variant}-@{value} {
      background-color: @@color !important;
    }

    .text-@{variant}-@{value} {
      @text-color: 'color-default-text-@{value}';
      color: if((@variant = default), @@text-color, @@color) !important;
    }

    .border-@{variant}-@{value} {
      border-color: @@color !important;
    }
  })
}

each(@color-variants, {
  .color-variant(@value);

  // default level (3):
  @color: 'color-@{value}-3';

  .bg-@{value} {
    background-color: @@color !important;
  }

  .text-@{value} {
    color: if((@value = default), @color-default-text-3, @@color) !important;
  }

  .border-@{value} {
    border-color: @@color !important;
  }
})

// </editor-fold>
// <editor-fold desc="Scroll">

::-webkit-scrollbar {
  width: .5rem;
  height: .5rem;
}

::-webkit-scrollbar-track {
  background: @color-default-1;
}

::-webkit-scrollbar-thumb {
  background: @color-default-3;
  border-radius: @radius-default;

  &:hover {
    background: @color-primary-4;
  }

  &:active {
    background: @color-primary-5;
  }
}

.scroll-momentum {
  .scroll-momentum();
}

// </editor-fold>

// <editor-fold desc="Lists">

ul {
  padding-left: 1.5rem;
  margin: 0;
}

.no-list {
  list-style: none;
  padding-left: 0;
}

// </editor-fold>

// <editor-fold desc="Recaptcha">

.grecaptcha-badge { visibility: hidden; }

// </editor-fold>
