@import '../../../../../submodules/src/styles/base';
@import '../../../../../submodules/src/styles/buttons';
@import '../../../../../submodules/src/styles/card';
@import '../../../../../submodules/src/styles/inputs';
@import '../../../../../submodules/src/styles/layout';
@import '../../../../../submodules/src/styles/modal';
@import '../../../../../submodules/src/styles/spacing';
@import '../../../../../submodules/src/styles/spinner';
@import '../../../../../submodules/src/styles/theme';
@import '../../../../../submodules/src/styles/typography';
@import '../../../../../submodules/src/styles/visibility';
@import '../../_variables';
@import '../../element/_theme';
@import '../../element/_content';
@import '../../element/_fonts';
@import "../../element/_popover";
@import '../../component/core/_header';
@import '../../element/_buttons';
@import '../../element/_mixins';
@import '../../element/_logos';
@import '../../element/_icons';
@import '../../element/_icons-mixins';
@import '../../element/_image';
@import '../../element/_to-review';
@import "../../element/slider";
@import "../../element/_collapsible";
@import '../../element/_breadcrumb';



// <editor-fold desc="GENERAL">

a {
  cursor: pointer;
  color: @color-default-5;

  &.disabled {
    color: @color-default-3;
    pointer-events: none;
    cursor: default;
  }
}

.clearfix {
  clear: both;
}

.clear {
  clear: both;
  float: none;
  height: 0 !important;
}

// </editor-fold>

// <editor-fold desc="ICONS">

// basic
.icon-close();
.icon-chevron-down();
.icon-chevron-up();
.icon-chevron-left();
.icon-chevron-right();

// header + footer
.icon-search();
.icon-bag();
.icon-indicates();
.icon-carrier();
.icon-carrier-car();
.icon-gallery();
.icon-exchange();
.icon-credit-card();
.icon-loyalty-card();

// </editor-fold>

//<editor-fold desc="Flex">

.d-flex, .d-inline-flex {
  &:not(.content):not(.flex-spaced-fixed) {
    max-width: 100%;
  }

  &.flex-spaced {
    justify-content: space-between;
  }

  &.flex-spaced-around {
    justify-content: space-around;
  }

  &.flex-spaced-fixed {
    margin: -.5rem;

    > * {
      margin: .5rem;
    }
  }

  > .flex-child-fixed {
    flex: 0 0 auto;
  }
}

//</editor-fold>


// <editor-fold desc="LINES AND BORDERS">
// todo remover e usar só o chicomponents

.no-border {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
}

.no-border-radius {
  border-radius: 0 !important;
}

.border-top {
  border-top: 1px solid @color-default-2;
}

.border-right {
  border-right: 1px solid @color-default-2;
}

.border-bottom {
  border-bottom: 1px solid @color-default-2;
}

.border-left {
  border-left: 1px solid @color-default-2;
}

.border-circle {
  border-radius: 50%;
}

.background-white {
  background-color: white;
}

// TODO: RETIRAR após Black Friday
.mobile-bf {
  min-height: 5rem;
}

.mobile-dflex-bf {
  padding-top: 35px;
}

.desktop-bf {
  min-height: 10rem;
}

.desktop-wrapper-bf {
  padding-top: 55px
}

.button-wrapper-bf {
  margin-top: 55px;
}

.alert-news {
  background-color: @color-primary-4 !important;
  color: @color-default-5 !important;
}

// </editor-fold>

// <editor-fold desc="Footer">
//footer {
//  .compact-footer();
//}
// </editor-fold>
