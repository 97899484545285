@import '../_variables';

.content-size(@width) {
  max-width: calc(@width + 2 * 1rem);
}

.base-content {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.content {
  &:extend(.base-content);
  .content-size(1250px);
}

.small-content {
  &:extend(.base-content);
  .content-size(400px);
}

.medium-content {
  &:extend(.base-content);
  .content-size(800px);
}

.large-content {
  &:extend(.base-content);
  .content-size(1200px);
}