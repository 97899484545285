@import 'variables';

// <editor-fold desc="Spacing">

.spacing-rules-generator(@property, @short-property, @suffix: ~'') {

  .axis-generator(@direction-a, @direction-b, @value) {
      @{property}-@{direction-a}: @value !important;
      @{property}-@{direction-b}: @value !important;
  }

  .@{short-property}x@{suffix} {
    .axis-generator(left, right, @spacer);
  }

  .@{short-property}y@{suffix} {
    .axis-generator(top, bottom, @spacer);
  }

  .@{short-property}x-auto@{suffix} {
    .axis-generator(left, right, auto);
  }

  .@{short-property}y-auto@{suffix} {
    .axis-generator(top, bottom, auto);
  }

  each(@spacer-factors, .(@factor, @factor-key, @factor-index) {
    @zero-based-index: @factor-index - 1;

    .@{short-property}x-@{zero-based-index}@{suffix} {
      .axis-generator(left, right, (@spacer * @factor));
    }

    .@{short-property}y-@{zero-based-index}@{suffix} {
      .axis-generator(top, bottom, (@spacer * @factor));
    }
  })

  each(@directions, .(@direction-short, @direction) {
    .@{short-property}@{direction-short}@{suffix} {
        @{property}-@{direction}: @spacer !important;
    }

    .@{short-property}@{direction-short}-auto@{suffix} {
        @{property}-@{direction}: auto !important;
    }

    each(@spacer-factors, .(@factor, @factor-key, @factor-index) {
      @zero-based-index: @factor-index - 1;
      .@{short-property}@{direction-short}-@{zero-based-index}@{suffix} {
          @{property}-@{direction}: (@spacer * @factor) !important;
      }
    })
  })
}

.spacing-rules-generator(margin, m);
.spacing-rules-generator(padding, p);

// </editor-fold>