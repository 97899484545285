@import 'mixins';

.card {
  .paper();

  box-shadow: @shadow-1;
  border-radius: @radius-default;

  > .card-block, > .card-table {
    &:last-child {
      border-bottom: none;
    }
  }
}

.card-title {
  margin: .5rem 0;
}

.card-toolbar {
  display: flex;
  align-items: center;

  > :not(:first-child) {
    margin-left: .25rem;
  }

  > .card-title {
    flex: 1 1 auto;
    margin-right: auto;
  }

  > .btn, .btn-group, .form-inline {
    flex: 0 0 auto;
  }
}

.card-block {
  padding: 1rem;
  border-bottom: 1px solid @color-default-2;
}

.card table {
  padding-top: .5rem;

  td, th {
    &:first-child {
      padding-left: 1rem;
    }

    &:last-child {
      padding-right: 1rem;
    }
  }
}