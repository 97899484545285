@import 'variables';
@import 'mixins';

input[type="search"] {
  box-sizing: border-box;

  &::-webkit-search-cancel-button {
    display: none;
  }
}

input[type=range] {
  position: relative;
  height: @input-border-width;
  margin: calc(.5rem - @input-border-width / 2) 0;
  background-color: @input-border-color;
  cursor: pointer;

  &::-webkit-slider-thumb,
  &::-moz-range-thumb {
    background-color: @color-white;
    border: @input-border-width solid @input-border-color;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
  }

  &:hover {
    background-color: @input-text-border-color-hover;

    &::-webkit-slider-thumb,
    &::-moz-range-thumb {
      background-color: @input-text-border-color-hover;
      border-color: @input-text-border-color-hover;
    }
  }
}

textarea {
  max-width: 100%;
  padding-top: .25rem;
  padding-bottom: .25rem;
}

fieldset {
  border: @input-border-width solid @input-border-color;
  padding: 1rem;
}

.form-group {
  display: block;
  margin-bottom: 1rem;

  // todo: remover
  span.label-danger {
    background-color: transparent;
    color: @color-danger-2;
    font-style: italic;
  }

  @media (@min-screen-xs) {
    span.label-danger {
      float: right;
    }
  }

  > label:first-child {
    display: block;
    margin-bottom: .5rem;
  }
}

select, input:not([type=button]):not([type=submit]), textarea {
  display: block;
  font-family: inherit;
  font-size: 1rem;
  padding-left: .5rem;
  padding-right: .5rem;
  text-align: inherit;

  .text-yanone & {
    font-size: 1.28rem;
  }

  @media (hover: none) {
    font-size: 16px; // pra não causar zoom no iOS
  }

  &:not([type=button]):not([type=submit]):not([type=file]):not([type=checkbox]):not([type=radio]) {
    width: 100%;
  }
}

input + .input-clear {
  .input-right-icon();
  pointer-events: auto;
  cursor: pointer;
  background-color: @color-default-text-5;
  mask: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'><path d='M34.962 32.5l11.113-11.2a2.394 2.394 0 0 0 0-3.36 2.35 2.35 0 0 0-3.336 0L31.985 28.775 21.232 17.942a2.344 2.344 0 0 0-3.335 0 2.444 2.444 0 0 0 0 3.36l11.122 11.2L17.905 43.7a2.367 2.367 0 0 0 3.335 3.36L32 36.228 42.749 47.06a2.344 2.344 0 0 0 3.335 0 2.444 2.444 0 0 0 0-3.36z' fill-rule='evenodd'/></svg>") no-repeat 50% 50%;
}

@media (@max-screen-xs) {
  .form-inline {
    display: block;

    > * {
      margin-bottom: .5rem;
      width: 100%;

      &:last-child {
        margin-bottom: 0
      }
    }

    > .btn, label {
      display: block;
    }

    > .btn-group {
      flex-wrap: wrap;

      > .btn {
        flex: 1 0 auto;
      }
    }
  }
}

@media (@min-screen-xs) {
  .form-inline {
    display: flex;
    align-items: center;

    > * {
      margin-bottom: 0;
      margin-top: 0;
    }

    > :not(:first-child) {
      margin-left: .5rem;
    }

    > :not(:last-child) {
      margin-right: .5rem;
    }

    > .cr-select,
    > textarea,
    > input:not([type=button]):not([type=submit]):not([type=file]):not([type=checkbox]):not([type=radio]) {
      width: auto;
    }

    > .cr-select,
    > .cr-autocomplete,
    > input:not([type=button]):not([type=submit]):not(label),
    > .input-group {
      flex: 1 1 auto;
    }

    .btn {
      flex-shrink: 0;
    }
  }
}

form dl {
  border: 1px solid @color-default-4;
  border-radius: .2rem;

  dt {
    padding: .5rem 1rem;

    &:not(:first-child) {
      border-top: 1px solid @color-default-4;
    }
  }

  dd {
    border-top: 1px solid @color-default-4;

    > div {
      padding: 1rem;
    }
  }
}

// todo remover
span.error {
  color: @color-danger-2;
}

input[type], select, textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input:not([type=button]):not([type=submit]):not(.cr-radio):not(.cr-checkbox), select {
  padding-top: 0;
  padding-bottom: 0;
}

input:not([type=button]):not([type=submit]):not([type=range]):not(.cr-radio):not(.cr-checkbox), select, textarea {
  .input-states();
  outline: none;
}

.cr-select {
  position: relative;

  &::after {
    .input-right-icon();
    background-color: @color-default-text-5;
    mask: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'><path d='M30.34 40a2.39 2.39 0 0 0 3.31 0l12.73-12.7a2.37 2.37 0 0 0 .69-1.66 2.34 2.34 0 0 0-.69-1.64 2.4 2.4 0 0 0-3.32 0L32 35 20.94 24a2.34 2.34 0 0 0-3.32 0 2.36 2.36 0 0 0 0 3.32z'/></svg>") no-repeat 50% 50%;
  }

  select {
    appearance: none;
    position: relative;
    padding-right: 2rem;
    padding-left: .5rem;
    background-color: @color-input-background;
    width: 100%;

    &[disabled], &.disabled {
      background-color: @color-default-1;
    }

    option {
      .paper(@color-default-1);
    }
  }
}

select, input:not([type=button]):not([type=submit]) {
  line-height: @input-height-xs;

  &.input-sm {
    line-height: @input-height-small-xs;
  }

  &.input-lg {
    font-size: 1.15em;
    line-height: @input-height-large-xs;
  }

  &.input-xl {
    line-height: @input-height-extra-large-xs;
  }
}

.cr-checkbox, .cr-radio, .cr-switch {
  opacity: 0;
  cursor: pointer;
  height: 0 !important;
  width: 0 !important;
  margin: 0;
  border: none !important;

  + label {
    position: relative;
    display: block;
    padding-left: 2rem;
    cursor: pointer;
    user-select: none;
    margin-top: 0;
    border: none;

    &::before {
      position: absolute;
      width: 1rem;
      height: 1rem;
      content: '';
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border: @input-border-width solid @color-default-5;
      transition: background-color @transition-curve @transition-duration, border-color @transition-curve @transition-duration;
    }
  }

  &[disabled], &.disabled {
    + label::before {
      border: @input-border-width solid @color-default-4;
    }
  }

  &:checked + label::before {
    border-color: @color-primary-5;
  }
}

.cr-checkbox {
  + label::before {
    border-radius: .2rem;
  }

  &:checked + label::before {
    background: @color-primary-5 url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='10 10 44 44'><path fill='white' d='M53.47 19.29a2.4 2.4 0 0 0-.7-1.7 2.46 2.46 0 0 0-3.39 0L25.64 41.33l-11-11a2.34 2.34 0 0 0-1.69-.71 2.4 2.4 0 0 0-1.7 4.09L24 46.41a2.47 2.47 0 0 0 3.39 0L52.77 21a2.38 2.38 0 0 0 .7-1.71z'/></svg>") no-repeat 50% 50%;
  }

  &:active:not([disabled]):not(.disabled) + label::before {
    background: @color-primary-3 url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='10 10 44 44'><path fill='white' d='M53.47 19.29a2.4 2.4 0 0 0-.7-1.7 2.46 2.46 0 0 0-3.39 0L25.64 41.33l-11-11a2.34 2.34 0 0 0-1.69-.71 2.4 2.4 0 0 0-1.7 4.09L24 46.41a2.47 2.47 0 0 0 3.39 0L52.77 21a2.38 2.38 0 0 0 .7-1.71z'/></svg>") no-repeat 50% 50% !important;
  }
}

.cr-radio {
  + label {

    &::before {
      border-radius: 1.3rem;
    }

    &:after {
      position: absolute;
      width: .5rem;
      height: .5rem;
      content: '';
      top: 50%;
      left: .5rem;
      transform: translate(-50%, -50%);
      border-radius: .35rem;
      transition: background-color @transition-curve @transition-duration;
    }
  }

  &:checked + label::before {
    background-color: transparent;
  }

  &:active:not([disabled]):not(.disabled) + label::before {
    background-color: transparent !important;
  }

  &:checked + label::after {
    background-color: @color-primary-5;
  }

  &:active:not([disabled]):not(.disabled) + label::after {
    background-color: @color-primary-4 !important;
  }
}

.cr-switch {
  + label {
    padding-left: 2.5rem;

    &::before {
      background-color: @color-default-4;
      width: 2rem;
      border-radius: .5rem;
      border: none;
    }

    &:after {
      position: absolute;
      width: .9rem;
      height: .9rem;
      content: '';
      top: 50%;
      left: .5rem;
      transform: translate(-50%, -50%);
      border-radius: .45rem;
      background-color: white;
      transition: background-color @transition-curve @transition-duration, margin-left @transition-curve @transition-duration;
    }
  }

  &:checked + label,
  &:active + label {
    &::before {
      background-color: @color-primary-4;
    }

    &::after {
      margin-left: .9rem;
    }
  }

  &[disabled], &.disabled {
    + label {
      &::before {
        background-color: @color-default-1;
      }

      &::after {
        background-color: @color-default-4;
      }
    }
  }
}

ul.input-options {
  .paper(@color-default-1);
  list-style: none;
  padding-left: 0;
  cursor: pointer;

  li {
    @media (hover: hover) and (pointer: fine) {
      &:hover, &.active {
        background-color: @color-primary-3;
        color: white;
      }
    }

    &.selected {
      background-color: @color-default-1;
    }

    &.input-option-multiline {
      padding: .5em .5rem;

      &:not(:last-child) {
        border-bottom: 1px solid @color-default-2;
      }
    }

    &:not(.input-option-multiline) {
      padding: 0 .5rem;
      line-height: @input-height-xs;

      @media (@min-screen-sm) {
        line-height: @input-height-sm;
      }
    }
  }
}

@media (@min-screen-sm) {

  select, input:not([type=button]):not([type=submit]) {
    line-height: @input-height-sm;

    &.input-sm {
      line-height: @input-height-small-sm;
    }

    &.input-lg {
      line-height: @input-height-large-sm;
    }

    &.input-xl {
      line-height: @input-height-extra-large-sm;
    }
  }

  @media (hover: hover)  and (pointer: fine) {
    .cr-checkbox, .cr-radio {
      &:not([disabled]):not(.disabled) + label:hover::before {
        border-color: @color-primary-3;
      }
    }

    .cr-radio:not([disabled]):not(.disabled) + label:hover::before {
      background-color: transparent;
    }

    .cr-switch:not([disabled]):not(.disabled) + label:hover::before {
      background-color: @color-primary-3;
    }
  }
}

.fieldset {
  background-color: transparent;
  border-radius: @radius-default;
  padding: 1rem;
  border: @input-border-width solid @color-default-2;

  span.fieldset-title {
    font-size: .9rem;
    color: @color-default-4;
    display: block;
    font-weight: bold;
    transform: translateY(-50%);
  }
}