@import '../../_variables';
@import '../../element/_expandable';

.header-brand {
  position: fixed;
  top: 0;
  left: 2rem;
  z-index: @max-z-index;
  .expandable(3rem, 2.5rem, 12.5rem);
}
