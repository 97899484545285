@import 'variables';
@import 'mixins';

html, body {
  font-size: 14px;
}

body {
  font-family: @font-secondary;
  color: @color-default-text-5;
}

p {
  margin: .5em 0;
}

.text-no-decoration {
  text-decoration: none !important;
}

.text-bold {
  font-weight: @bold-weight !important;
}

.text-italic {
  font-style: italic !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-secondary {
  color: @color-default-text-4 !important;
}

.text-muted {
  color: @color-default-text-2 !important;
}

.text-no-transform {
  text-transform: none !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.line-through {
  text-decoration: line-through !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-nowrap {
  white-space: nowrap;
}

.text-ellipsis {
  .text-ellipsis();
}

.line-clamp() {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-3 {
  .line-clamp();
  -webkit-line-clamp: 3;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
  display: block;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: @font-primary;
  font-weight: @regular-weight;
  margin: 1em 0 .5em 0;
  line-height: 1.1;
}

// <editor-fold desc="Sizes">

each(@headings-sizes-xs, {
  @size: @{value}rem;

  h@{index}, .h@{index} {
    font-size: @size;
  }

  .text-size-@{index} {
    font-size: @size !important;
  }

  @media (@min-screen-xs) {
    .text-size-@{index}-xs {
      font-size: @size !important;
    }
  }
});

each(@headlines-sizes-xs, {
  h@{index}, .h@{index} {
    &.headline {
      @size: @{value}rem;
      font-size: @size;
    }
  }
});

.text-regular {
  font-size: 1rem !important;
}

.text-medium {
  font-size: 0.9rem !important;
}

small, .text-small {
  font-size: 0.8rem !important;
}

@media (@min-screen-sm) {
  body {
    line-height: 1.5;
  }

  h1, h2, h3, h4, h5,
  .h1, .h2, .h3, .h4, .h5 {
    &.border-bottom {
      display: inline-block;
    }
  }

  each(@headings-sizes-sm, {
    @size: @{value}rem;

    h@{index}, .h@{index} {
      font-size: @size;
    }

    .text-size-@{index} {
      font-size: @size !important;
    }
  });

  each(@headings-sizes-sm, {
    .text-size-@{index}-sm {
      @size: @{value}rem;
      font-size: @size !important;
    }
  });

  each(@headlines-sizes-sm, {
    h@{index}, .h@{index} {
      &.headline {
        @size: @{value}rem;
        font-size: @size;
      }
    }
  });
}

each(@headings-sizes-sm, {
  @size: @{value}rem;
  @media (@min-screen-md) {
    .text-size-@{index}-md {
      font-size: @size !important;
    }
  }
});

each(@headings-sizes-sm, {
  @size: @{value}rem;
  @media (@min-screen-lg) {
    .text-size-@{index}-lg {
      font-size: @size !important;
    }
  }
});