@import 'variables';
@import 'mixins';

// <editor-fold desc="Modal">

.cr-modal {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: none;
  opacity: 0;
  transition: opacity @transition-duration @transition-curve;

  .cr-modal-dialog {
    position: relative;
    opacity: 0;
    margin: 2rem;
    height: calc(100vh - 4rem);
  }

  .modal-size();

  &.opened {
    display: block;
    animation: showModal .4s forwards;

    .cr-modal-dialog {
      opacity: 1;
      animation: showModalBody .4s;
    }
  }

  &.large {
    .cr-modal-dialog {
      @media (@min-screen-xs) {
        width: 1000px;
      }
    }
  }

  &.alert {
    .cr-modal-dialog {
      text-align: center;

      ul {
        text-align: left;
      }

      @media (@min-screen-xs) {
        width: 400px;
        margin-top: 30vh;
      }
    }

    .cr-modal-footer {
      justify-content: center;
    }
  }
}

@keyframes showModal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes showModalBody {
  from {
    top: -300px;
  }
  to {
    top: 0;
  }
}
// </editor-fold>