@import 'variables';

// <editor-fold desc="Visibility">

.hidden {
  display: none !important;
}

.hidden-print {
  @media print {
    display: none !important;
  }
}

.hidden-touch {
  @media (pointer: coarse) {
    display: none !important;
  }
}

.hidden-not-touch {
  @media (pointer: fine) {
    display: none !important;
  }
}

.hidden-xs {
  @media (@max-screen-sm) {
    display: none !important;
  }
}

.hidden-sm {
  @media (@min-screen-sm) and (@max-screen-md) {
    display: none !important;
  }
}

.hidden-md {
  @media (@min-screen-md) and (@max-screen-lg) {
    display: none !important;
  }
}

.hidden-lg {
  @media (@min-screen-lg) {
    display: none !important;
  }
}

.visible-xs {
  display: block;

  @media (@min-screen-sm) {
    display: none !important;
  }
}

.visible-sm {
  display: block;

  @media (@max-screen-sm), (@min-screen-md) {
    display: none !important;
  }
}

.visible-md {
  display: block;

  @media (@max-screen-md), (@min-screen-lg) {
    display: none !important;
  }
}

.visible-lg {
  display: block;

  @media (@max-screen-lg) {
    display: none !important;
  }
}

each(@breakpoints, {
  @min: 'min-screen-@{value}';
  @max: 'max-screen-@{value}';

  @media (@@min) {
    .hidden-@{value}-up {
      display: none !important;
    }
  }

  @media (@@max) {
    .hidden-@{value}-down {
      display: none !important;
    }
  }
})

// </editor-fold>

// <editor-fold desc="Vue">

.vue-rendered + .vue-placeholder {
  display: none !important;
}

// </editor-fold>

// <editor-fold desc ="Delay">

.delay-show {
  opacity: 0;
  animation-name: delay;
  animation-fill-mode: forwards;
  animation-delay: .5s;
  animation-duration: .5s;
}

@keyframes delay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// </editor-fold>

// <editor-fold desc ="Fade">

@fade-values: .2, .4, .6, .8;

.fade-0 {
  opacity: 0;
}

each(@fade-values, {
  .fade-@{index} {
    opacity: @value;
  }
});

// </editor-fold>