@import 'variables';
@import 'mixins';

// <editor-fold desc="Alignment">

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

// </editor-fold>

// <editor-fold desc="Sizing">

.w-1\/2 {
  width: 50% !important;
}

.h-1\/2 {
  height: 50% !important;
}

.w-full {
  width: 100% !important;
}

.h-full {
  height: 100% !important;
}

.w-screen {
  width: 100vw !important;
}

.h-screen {
  height: 100vh !important;
}

each(@spacer-factors, .(@factor, @factor-key, @factor-index) {
  @zero-based-index: @factor-index - 1;

  .w-@{zero-based-index} {
    width: @spacer * @factor;
  }

  .h-@{zero-based-index} {
    height: @spacer * @factor;
  }
})

// </editor-fold>

// <editor-fold desc="Display">

@displays: none, block, flex, inline, inline-block, inline-flex;

each(@displays, {
  .d-@{value} {
    display: @value !important;
  }
})

// </editor-fold>

// <editor-fold desc="Positioning">

.pos-relative {
  position: relative !important;
}

.pos-absolute {
  position: absolute !important;
}

.pos-fixed {
  position: fixed !important;
}

.pos-all {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.pos-bottom {
  bottom: 0;
}

.pos-top {
  top: 0;
}

.pos-left {
  left: 0;
}

.pos-right {
  right: 0;
}

.pos-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pos-center-x {
  left: 50%;
  transform: translateX(-50%);
}

.pos-center-y {
  top: 50%;
  transform: translateY(-50%);
}

// </editor-fold>

// <editor-fold desc="Flexbox">

.flex-column {
  flex-flow: column;
}

.flex-align-center {
  align-items: center !important;
}

.flex-align-baseline {
  align-items: baseline !important;
}

.flex-align-stretch {
  align-items: stretch !important;
}

.flex-align-start {
  align-items: flex-start !important;
}

.flex-align-end {
  align-items: flex-end !important;
}

.flex-align-end {
  align-items: flex-end !important;
}

.flex-self-stretch {
  align-self: stretch !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-justify-center {
  justify-content: center !important;
}

.flex-justify-around {
  justify-content: space-around !important;
}

.flex-justify-between {
  justify-content: space-between !important;
}

.flex-item-grow {
  flex-grow: 1;
}

.flex-item-shrink {
  flex-shrink: 1;
}

.flex-item-align-center {
  align-self: center;
}

// </editor-fold>

// <editor-fold desc="Elevations">

each(@elevation-levels, {
  .z-@{value} {
    z-index: @value;
  }
});

.z-max {
  z-index: @max-z-index;
}

// </editor-fold>

// <editor-fold desc="Shadows">

each(@elevation-levels, {
  .shadow-@{value} {
    @shadow: 'shadow-@{value}';
    box-shadow: @@shadow !important;
  }
});

// </editor-fold>

// <editor-fold desc="Separators">

hr {
  margin: 1rem 0;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid @color-default-2;
  padding: 0;
}

// </eitor-fold>

// <editor-fold desc="Border">

.border-radius, .rounded {
  border-radius: @radius-default;
}

.rounded-full {
  border-radius: 9999px;
}

// </editor-fold>

// <editor-fold desc="Overflow">

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.overflow-x-auto {
  overflow-x: auto;
}

//// </editor-fold>

// <editor-fold desc="Scroll">

.scrollable-container {
  max-height: 100%;
  display: flex;
  flex-direction: column;

  .scrollable-content {
    flex: 1 1 auto;
    overflow-y: auto;
  }
}

.no-scrollbar {
  -ms-overflow-style: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

// </editor-fold>