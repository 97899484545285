//<editor-fold desc="Color CSS Variables">

body {
  --color-white: white;
  --color-black: black;

  --color-default-0: white;
  --color-default-1: #EDEDED;
  --color-default-2: #D8D8D8;
  --color-default-3: #5C5C5C;
  --color-default-4: #414141;
  --color-default-5: #181818;
  --color-default-text-1: #EDEDED;
  --color-default-text-2: #D8D8D8;
  --color-default-text-3: #818181;
  --color-default-text-4: #414141;
  --color-default-text-5: #181818;
  --color-danger-1: #DEB1B1;
  --color-danger-2: #D69E9E;
  --color-danger-3: #A52B2B;
  --color-danger-4: #882424;
  --color-danger-5: #6A1C1C;
  --color-primary-1: #91D6E6;
  --color-primary-2: #15B6DA;
  --color-primary-3: #0C81B7;
  --color-primary-4: #1369A6;
  --color-primary-5: #0A4E8E;
  --color-info-1: #7DC9DC;
  --color-info-2: #6BC1D7;
  --color-info-3: #34ABC9;
  --color-info-4: #2B8CA5;
  --color-info-5: #226D80;
  --color-success-1: #B0D2CE;
  --color-success-2: #9CC7C2;
  --color-success-3: #268479;
  --color-success-4: #206D64;
  --color-success-5: #19544E;
  --color-warning-1: #EBC182;
  --color-warning-2: #D3A55F;
  --color-warning-3: #CA933F;
  --color-warning-4: #A3732C;
  --color-warning-5: #805B23;

  --color-table-row-1: var(--color-default-1);
  --color-table-row-2: white;
  --color-table-row-hover: white;

  --color-input-background: white;
}

//</editor-fold>
// <editor-fold desc="Typography CSS Variables">

body {
  --regular-weight: 400;
  --bold-weight: 700;
}

// </editor-fold>