body {
  --color-white: #FFFFFF;
  --color-black: #000000;

  //    --color-default-1: #E0D8C1; // todo
  //    --color-default-2: #C4B487;
  //    --color-default-3: #8F8058;
  //    --color-default-4: #6E4A3A;
  //    --color-default-5: #231F20;

  //    --color-default-text-1: #E0D8C1; // todo
  //    --color-default-text-2: #C4B487;
  //    --color-default-text-3: #8F8058;
  //    --color-default-text-4: #6E4A3A;
  //    --color-default-text-5: #231F20;

  --color-default-1: #f7f7f7;
  --color-default-2: #dedede;
  --color-default-3: #827E73;
  --color-default-4: #636057;
  --color-default-5: #2E2C29;

  --color-default-text-1: #EFEAD9;
  --color-default-text-2: #E7E1CD;
  --color-default-text-3: #827E73;
  --color-default-text-4: #636057;
  --color-default-text-5: #2E2C29;

  --color-primary-1: #FFC100; // todo
  --color-primary-2: #FFC100;
  --color-primary-3: #FFC100;
  --color-primary-4: #B8A777;
  --color-primary-5: #FFC100;

  --color-info-1: #FC005B; // todo
  --color-info-2: #FC005B;
  --color-info-3: #FC005B;
  --color-info-4: #FC005B;
  --color-info-5: #490017;

  --color-success-1: #BFF3C6;
  --color-success-3: #1C792C;
  --color-success-5: #1B6027;

  --color-warning-1: #F0A53C;
  --color-warning-5: #8B3215;

  --color-table-row-2: #ECECE8;
}
