@import '../_variables';

.expandable(@compact-width, @compact-height, @expanded-width) {
  width: @compact-width;
  min-height: @compact-height;
  max-height: @compact-height;
  transition: width @transition-default, max-height @transition-default;

  &:hover {
    width: @expanded-width;
    max-height: 10000px;

    .expandable-content {
      pointer-events: auto;
      opacity: 1;
    }
  }

  .expandable-compact {
    position: absolute;
    z-index: 1;
    top: 0;

    .header-account-compact-body {
      width: @compact-width;
      height: @compact-height;
    }
  }

  .expandable-content {
    z-index: 2;
    min-width: @expanded-width;
    transition: opacity @transition-default;
    pointer-events: none;
    opacity: 0;
    max-width: 4.4rem;
  }
}
